<template>
  <div class="home">
    <div class="banner-box">
      <!--顶部-->
      <header-nav :current="current" :bgHeaderNav="false" :searchshow="true"></header-nav>
      <div class="banner-info">
        <!-- <h4>http://www.xarw365.com/</h4> -->
<!--        <h1>多模态儿童成长规划系统</h1>-->

        <div class="banner-tips">
          <h>技术介绍</h>
          <p @click="$router.push('/newsinfo?aid=' + list.id)" v-for="(list, index) in newsList" :key="index">
            {{list.title}}</p>

            <div class="title_img">
              <div>
                <img src="../assets/icon/t1.png" alt="">
               
              </div>
              <div>
                <img src="../assets/icon/t2.png" alt="">
               
              </div>
              <div>
                <img src="../assets/icon/t3.png" alt="">
                
              </div>
              <div>
                <img src="../assets/icon/t4.png" alt="">
                
              </div>
            </div>

        </div>
      
      </div>
    </div>
    <div class="sections">
<!--      <p>量表推荐</p>-->
      <div class="ksp">
        <div class="xc">
          <div class="msgs" @click="$router.push('/testcenter?type_id=14&id=14')">
            <div class="pics">
              <!--          <span>儿童感觉统合功能评量表</span>-->
              <img src="../assets/img/ydx02.png" alt="">
            </div>
            <!--        <div class="info">-->
            <!--          <p>本测验是参照国外学者A. C. Bundy与A. G. Fisher提供的架构，由台湾职能治疗师林巾凯教授带领其他七位职能治疗师搜集国内外感觉统合文献与评量工具自行编制題目，经过预试与修改，并进行严谨的信效度验证，最后编制而成本测验。因学龄前与学龄儿童所处的环境不同，因此将本测验分成学前版与学龄版，以利评量者进行详细的评估。本测验可作为了解儿童感觉统合问题的参考。</p>-->
            <!--        </div>-->
          </div>
          <div class="msgs rev" @click="$router.push('/testcenter?type_id=13&id=13')">
            <!--        <div class="info">-->
            <!--          <p>-->
            <!--            本测验是由台湾学者张韶霞、余南莹统整国内外写字评量工具之题项，以文献理论为基础，并经由信效度分析验证，发展编制成为可以分析写字困难亚型与写字先备能力程度的测验。本测验依照儿童发展程度不同，分成学前版与学龄版，可协助学校教师与临床工作人员对于儿童的写字能力与发展有更深入的剖析，同时在拟定写字困难介入计划时能提供重要的参考指标。</p>-->
            <!--        </div>-->
            <div class="pics">
              <!--          <span>儿童写字表现评量表 <br>(CHEF)</span>-->
              <img src="../assets/img/ydx00.png" alt="">
            </div>
          </div>
        </div>
        <div class="xc">
          <div class="msgs1" @click="$router.push('/testcenter?type_id=11&id=11')">
            <div class="pics">
              <!--          <span>零岁至三岁汉语婴幼儿沟通及语言筛查测验<br>（CLST）</span>-->
              <img src="../assets/img/ydx03.png" alt="">
            </div>
            <!--        <div class="info">-->
            <!--          <p>-->
            <!--            本测验是由台湾语言治疗专家黄瑞珍、李佳妙、黄艾萱、吴佳锦、卢璐参考国内外文献与相关测验，基于汉语语言学特质，探讨汉语的特定语音、语意、语法结构，与婴幼儿语言习得顺序编制而成，并经过了严谨的信效度分析验证。本测验主要作为评估零岁至三岁婴幼儿语言及沟通发展情况的筛查工具。目的是通过测验，初步筛查出零岁至三岁疑似语言发展迟缓的婴幼儿，以便进行早期干预和治疗，并根据筛查测验结果分析，进一步了解婴幼儿的语言发展能力。</p>-->
            <!--        </div>-->
          </div>
          <div class="msgs rev1" @click="$router.push('/testcenter?type_id=12&id=12')">
            <!--        <div class="info">-->
            <!--          <p>本测验是由台湾学者赵家琛、吴怡慧、曹光文、陈明终修订自S. D. Mayes所编制的Checklist for Autism Spectrum-->
            <!--            Disorder（CASD），采自闭症光谱的概念设计，与DSM-5方向一致，亦符合DSM-5的诊断准则。测验内容检核自闭症类群障碍的六大行为特征，包含社会互动问题、持续重复行为、体觉困扰、非典型沟通及发展、情绪困扰、注意力与安全问题，可快速、有效鉴别自闭症类群障碍儿童。</p>-->
            <!--        </div>-->
            <div class="pics">
              <!--          <span>自闭症类群障碍检核表（汉语版）<br>（CASD-C）</span>-->
              <img src="../assets/img/ydx01.png" alt="">
            </div>
          </div>
        </div>
        <div class="xc">
          <div class="msgs2" @click="$router.push('/testcenter?type_id=10&id=10')">
            <div class="pics">
              <!--          <span>社会情绪<br>（CLST）</span>-->
              <img src="../assets/img/ydx04.png" alt="">
            </div>
            <!--        <div class="info">-->
            <!--          <p>-->
            <!--            本测验是由台湾语言治疗专家黄瑞珍、李佳妙、黄艾萱、吴佳锦、卢璐参考国内外文献与相关测验，基于汉语语言学特质，探讨汉语的特定语音、语意、语法结构，与婴幼儿语言习得顺序编制而成，并经过了严谨的信效度分析验证。本测验主要作为评估零岁至三岁婴幼儿语言及沟通发展情况的筛查工具。目的是通过测验，初步筛查出零岁至三岁疑似语言发展迟缓的婴幼儿，以便进行早期干预和治疗，并根据筛查测验结果分析，进一步了解婴幼儿的语言发展能力。</p>-->
            <!--        </div>-->
          </div>
        </div>

      </div>
      <div class="tujian">
<!--        <img src="../assets/img/img_explain_title.png" alt="">-->
<!--        <div>-->
<!--          <p>康复治疗及特殊教育有关从业人员可发起使用申请。包括：医院康复师、医师，公、民办特殊教育单位老师，以及涉及特殊教育工作的普教老师，等。另需参加本公司不定期安排之测验研习，通过考核者方可获得使用资格。</p>-->
<!--        </div>-->
      </div>
      <div class="tujian1">
        <img src="../assets/img/img_step2.png" alt="">
      </div>
      <div class="banquan">
<!--        <p>版权及其他声明</p>-->
<!--        <div>-->
<!--          <p>1. 本公司所有测验均经国内外著作权人及台湾心理出版社正式授权发行，由专家学者严谨编制修订，并获《著作权法》之保障，独家发行。无本公司的书面授权，不得私下影印、印刷、复制、修改、出版或以其他形式使用任何部分，如经发现，将依法追究。</p>-->
<!--          <p>2. 请遵守测验伦理，所申请通过之测验仅限申购单位及个人自用，不可外借，或做其他商业用途，并须严加管制，以免遭受误用或滥用。施测者应依据该测验之标准化程序施测、计分与解释。测验之原始资料、结果报告及建议内容，应视为专业机密；若为咨询、研究或教育训练目的，则不得透露受试者的身份。<p>-->
<!--          <p>3. 本公司仅同意研究人员于研究报告中简述该测验之内容，不得将题目放置于研究报告中。若希望获得其他授权（例如：将测验之一部分或全部内容重印、修改、地区化、计算机化，或附于研究报告后等），需先经本公司的正式书面授权。</p>-->
<!--          <p>4. 测验申请之前，请先详细阅读测验介绍或洽询本公司（微信号：15092285983）。</p>-->
<!--          <p>5. 凡本网站所载之测验，皆需参与相关研习，以获得使用技能，第一次授权自带一定数量之免费测验额度，用罄后需在线购买。</p>-->
<!--          <p>6. 测验开发成本甚高，恕无任何折扣，敬请见谅。</p>-->
<!--          <p>7. 本公司提供测验内容咨询、计分解释与测验研习活动之服务，详情请参见本网站【资讯信息】栏目通知，或关注【喜安课堂】服务号。</p>-->
<!--        </div>-->
      </div>
      <!-- <div class="section img_bg1 on">
        <div class="box">
          <h2>如何进入、离开本系统</h2>
          <h6 class="title-tips"><span>详情介绍</span></h6>
          <div class="paragraph">当您一进入本系统，您就必须输入一组用户账号及用户密码，以确认您的身份及类型。<br />当您的上级管理者将您的数据建入系统时，就会给您一组用户账号及用户密码，如果您还不知道您的用户账号及用户密码，请向您的上级管理者询问。当您输入用户密码时，您所输入的文字并不会出现在字段内，而是一串的黑点代替，以保证密码的隐密性。按下确定送出按钮，系统就会开始检查您所输入的用户账号和用户密码。</div>
        </div>
      </div>-->
      <!-- <div class="section">
        <div class="box">
          <h2>进入本系统的主画面</h2>
          <h6 class="title-tips"><span>详情介绍</span></h6>
          <div class="paragraph">当您登入成功后，首先系统会先判断密码长度，密码长度若小于12码者会出现「密码长度至少为12码，<br />请重新设定您的密码!!」并跳至重设密码画面。<br />接着系统会判断您的密码变更天数是否已超过60天，若超过者，会出现「距上次密码变更已超60过天，请变更您的密码!!」的讯息画面，并跳至重设密码画面。请在此变更密码，储存后重新登入系统。</div>
        </div>
      </div>-->
      <div class="section section-bottom">
        <div class="box">
<!--          <div class="title">-->
<!--            <h1>关注喜安公众号</h1>-->
<!--            <p @click="$router.push('/news')">获取更多相关资讯</p>-->
<!--          </div>-->
<!--          <div class="code">-->
<!--            <div class="imgs">-->
<!--              <img style="width:108px" src="../assets/img/xian_1.jpg" alt="">-->
<!--              <p>喜安人文订阅号</p>-->
<!--            </div>-->
<!--            <div class="imgs">-->
<!--              <img style="width:108px" src="../assets/img/xian_2.jpg" alt="">-->
<!--              <p>喜安课堂服务号</p>-->
<!--            </div>-->
<!--          </div>-->
        </div>
      </div>
    </div>
    <!--底部-->
    <footer-nav></footer-nav>
  </div>
</template>

<script>
    import HeaderNav from "@/components/HeaderNav";
    import FooterNav from "@/components/FooterNav";

    export default {
        name: "user",
        data() {
            return {
                current: "home",
                article1: {},
                article2: {},
                article3: {},
                websiteData: {},
                newsList: []
            };
        },
        components: {
            HeaderNav,
            FooterNav
        },
        methods: {
            getNews: function () {
                let params = {
                    page: 1,
                    limit: 5
                };
                this.$post("index/articleList", params)
                    .then(res => {
                        if (res.code === 1) {
                            this.newsList = res.data.data;
                        }
                    })
                    .catch(res => {
                        this.$layer.msg("网络异常，请稍后重试！");
                    });
            }
        },
        created() {
            this.getNews()
        }
    };
</script>
<style lang="less" scoped>
  @import url("../assets/css/page/index");
</style>
